import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import im1 from "../Assests/Image/1-min.jpg"
import im2 from "../Assests/Image/10-min.jpg"
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'

function Foot() {
  return (
    <div>
        <div>
      <div>
       <div>
      <div>
        <Header />
        <div className=''>
          <div className='position-relative'>
            <img src='https://www.magnasecurity.ca/wp-content/uploads/2023/10/INSIDE-BANNER-6.jpg' className='w-100 object-fit-cover' height={400} />
            <div className='position-absolute top-0 header-overlay w-100'>
            </div>
            <div className='position-absolute top-0 h-400 w-100 d-flex justify-content-center align-items-center'>
              <h1 className='text-white fw-bolder ff-lobster'>Foot Patrol</h1>
            </div>
          </div>
        </div>

        <div>
          <Row >
            <Col>
              <div className='h-100 d-flex flex-column justify-content-center px-4'>
                <h1 className='fw-bolder ff-lobster'>Deter Crime & Secure Property and Assets With In Action Mobile Patrol Security Services</h1>
                <p className='mt-3'>In Action Security offers affordable and reliable mobile patrol security services. Our security guards patrol the premises on foot or in patrolling cars, providing a visible presence to deter criminals and discourage unlawful activities.</p>
                <h1 className='fw-bolder ff-lobster'>What are Mobile Patrol Security Services?</h1>
                <p className='mt-3'>Our mobile patrol security services involve security guards who move around a property or area to keep it safe. They are not static and offer wider coverage of the area. In Action Security guards patrol on foot or in cars and are able to quickly respond to any issues. We protect areas like construction sites, retail stores, neighborhoods, and more.</p>
              </div>
            </Col>
            <Col>
              <img src={im1} className='w-100' />
            </Col>
          </Row>
        </div>

        <div>
          <div className='bg-blue text-white py-5'>
            <div className='d-flex justify-content-center'>
              <div className='d-none d-lg-block w-50'>
                <div className='text-center'>
                  <h1 className='fw-bold ff-lobster'>
                    Get Your Security Needs Assessed Under 30 MINUTES
                  </h1>
                </div>
                <div className='text-black mx-5 my-5'>
                  <Row>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Full Name"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Fullname" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control type="email" placeholder="Enter your email" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Contact Number"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Phone Number" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                        <Form.Select aria-label="Floating label select example">
                          <option>Select type of Secuirty</option>
                          <option> EVENT SECURITY</option>

                          <option> RESIDENTIAL SECURITY</option>
                          <option> INDUSTRIAL SECURITY</option>

                          <option> MOBILE PATROL</option>

                          <option> FOOT PATROL</option>

                          <option> NEIGHBORHOOD WATCH</option>

                          <option> FIRE WATCH</option>

                          <option> CONSTRUCTION</option>

                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <div className='mt-3'>
                      <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                    </div>
                  </Row>
                  <div className='text-white text-center mt-4'>
                    <p>
                      Thank You For Contacting Inaction Security
                      <br />
                      We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                    </p>
                  </div>
                </div>
              </div>
              <div className='d-block d-lg-none'>
                <div className='text-center'>
                  <h1 className='fw-bold ff-lobster'>
                    Get Your Security Needs Assessed Under 30 MINUTES
                  </h1>
                </div>
                <div className='text-black mx-5 my-5'>
                  <Row>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Full Name"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Fullname" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control type="email" placeholder="Enter your email" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Contact Number"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Phone Number" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                        <Form.Select aria-label="Floating label select example">
                          <option>Select type of Secuirty</option>
                          <option> EVENT SECURITY</option>

                          <option> RESIDENTIAL SECURITY</option>
                          <option> INDUSTRIAL SECURITY</option>

                          <option> MOBILE PATROL</option>

                          <option> FOOT PATROL</option>

                          <option> NEIGHBORHOOD WATCH</option>

                          <option> FIRE WATCH</option>

                          <option> CONSTRUCTION</option>

                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <div className='mt-3'>
                      <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                    </div>
                  </Row>
                  <div className='text-white text-center mt-4'>
                    <p>
                      Thank You For Contacting Inaction Security
                      <br />
                      We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Row >
            <Col>
              <img src={im2} className='w-100' />
            </Col>
            <Col>
              <div className='h-100 d-flex flex-column justify-content-center px-4'>
                <h1 className='fw-bolder ff-lobster'>Top-Tier Protection with In Action Security: Custom Plans and Advanced Tech:</h1>
                <p className='mt-3'>Our Mobile patrol security thoroughly assesses your property and designs a customized security plan tailored to your needs. As an extension of your team, we offer security services even when you are not physically present.
                In Action mobile security services utilize GPS-based tracking technology to monitor patrols and improve response times. We offer nighttime security and flexible patrol schedules to meet your specific requirements. In addition to offering the best security services, our guards excel in customer service and work closely with law enforcement to maintain a safe environment. Our team manages access control, ensuring only authorized individuals enter your premises.</p>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Foot
