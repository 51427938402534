import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './Component/AboutUs';
import Gallery from './Component/Gallery';
import Careers from './Component/Careers';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Foot from './Pages/Foot';
import EventPage from './Pages/EventPage';
import Industry from './Pages/Industry';
import Regdential from './Pages/Regdential';
import Mobile from './Pages/Mobile';
import Nib from './Pages/Nib';
import Fire from './Pages/Fire';
import Constration from './Pages/Constration';
function App() {
  AOS.init();
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/inaction' element={<Gallery />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/foot-patrol' element={<Foot />} />
          <Route path='/event-security' element={<EventPage />} />
          <Route path='/industrial-security' element={<Industry />} />
          <Route path='/residential-security' element={<Regdential />} />
          <Route path='/mobile-patrol' element={<Mobile />} />
          <Route path='/neighborhood-patrol' element={<Nib />} />
          <Route path='/fire-watch' element={<Fire />} />
          <Route path='/construcion-security' element={<Constration />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
