import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import img1 from "../Assests/Image/slide-img.jpg"
import Getaquate from './Modal/Getaquate';
function Hero() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    const [modal, setModal] = useState(false)
    return (
        <div>
            <div className='d-none d-lg-block'>
                <div className='position-relative h-100 overflow-hidden'>
                    <div className='h-100'>
                        <Slider {...settings}>
                            <div className='h-100'>
                                <img src={img1} className='w-100 object-fit-cover' height={585} />
                            </div>
                            <div className='h-100'>
                                <img src={"https://www.allstarsecurityservice.com/wp-content/uploads/2018/07/main-banner.jpg"} className='w-100 object-fit-cover h-100' height={580} />
                            </div>
                            <div className='h-100'>
                                <img src={"https://www.allstarsecurityservice.com/wp-content/uploads/2018/07/main-banner.jpg"} className='w-100 object-fit-cover h-100' height={580} />
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className='container pt-3 h-100 position-absolute top-0 w-100'>
                    <div className='container h-100 ms-lg-5 ps-lg-4'>
                        <Row className='h-100'>
                            <Col className='text-white h-100' xs={12} md={6}>
                                <div className='d-flex flex-column justify-content-center align-items-center h-100 pt-lg-5 mt-lg-5'>
                                    <div className='d-flex justify-content-start w-100'>
                                        <h1 className='clr-yellow fw-bold ff-lobster text-uppercase'>In Action</h1>
                                    </div>
                                    <h1 className='clr-yellow fw-bold ff-lobster text-uppercase'>Canada's Trusted Security Company for All Your Security Needs</h1>
                                    <p className='mt-3 '>
                                        Experience top-tier protection with IN ACTION 24/7 SECURITY: bespoke services, expert guards, instant reporting, and 24/7 support ,
                                        IN ACTION 24/7 SECURITY is a leading provider in Ontario, specializing in GTA areas. We offer physical and procedural security services with highly trained static and mobile guards for residential, commercial, and industrial needs. Our commitment includes real-time monitoring and ongoing training to stay ahead of threats.
                                    </p>
                                    <div className='d-flex justify-content-start w-100 mt-4'>
                                        <button className='btn bg-red text-uppercase px-3 py-2' onClick={() => { setModal(true) }}>Get A Quote</button>
                                    </div>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className='hero-bg d-block d-lg-none'>
                <div className='container  h-100  w-100'>
                    <div className='container h-100 ms'>
                        <Row className='h-100'>
                            <Col className='text-white h-100' xs={12} md={6}>
                                <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                    <div className='d-flex justify-content-start w-100'>
                                        <h1 className='clr-yellow fw-bold ff-lobster text-uppercase'>In Action</h1>
                                    </div>
                                    <h1 className='clr-yellow fw-bold ff-lobster  text-uppercase'>Canada's Trusted Security Company for All Your Security Needs</h1>
                                    <p className='mt-3'>
                                        Experience top-tier protection with IN ACTION 24/7 SECURITY: bespoke services, expert guards, instant reporting, and 24/7 support ,
                                        IN ACTION 24/7 SECURITY is a leading provider in Ontario, specializing in GTA areas. We offer physical and procedural security services with highly trained static and mobile guards for residential, commercial, and industrial needs. Our commitment includes real-time monitoring and ongoing training to stay ahead of threats.
                                    </p>
                                    <div className='d-flex justify-content-start w-100 mt-4'>
                                        <button className='btn bg-red text-uppercase px-3 py-2' onClick={() => { setModal(true) }}>Get A Quote</button>
                                    </div>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>
                </div>
                <Getaquate open={modal} close={() => { setModal(false) }} />
            </div>
        </div>
    )
}

export default Hero
