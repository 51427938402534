import React, { useState } from 'react';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import Logo from '../Assests/Image/logo.jpg';
import Getaquate from './Modal/Getaquate';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    return (
        <div>
            <div className='bg-blue py-3'>
                <div className='d-flex justify-content-between container text-white flex-wrap'>
                    <div>
                        <span className='c'>operations@inaction247.com</span>
                    </div>
                    <div>
                        <a href='tel:+14374382323' className='text-white'>437-438-2323</a>
                    </div>
                </div>
            </div>
            <div className='container'>
                <Navbar key="md" expand="md" sticky='top' className="mb-3">
                    <Container fluid>
                        <Navbar.Brand href="#">
                            <img src={Logo} width={70} className='rounded-circle' alt="Logo" onClick={() => {
                                navigate('/')
                            }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-md`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                    In Action
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center flex-grow-1">
                                    <Link to={"/"} className='ff-baloo nav-link nav-title'>Home</Link>
                                    <Link to="/inaction" className='ff-baloo nav-link nav-title'>In Action</Link>
                                    <div className='dropdown-container'>
                                        <Nav.Link href="#action2" className='ff-baloo nav-link nav-title dropdown-toggle'>Services</Nav.Link>
                                        <div className='dropdown py-4 px-3 position-absolute'>
                                            <Link to={"/event-security"} className='dropdown-item ff-baloo nav-link'>EVENT SECURITY</Link>
                                            <Link to={"/residential-security"} className='dropdown-item ff-baloo nav-link'>RESIDENTIAL SECURITY</Link>
                                            <Link to={"/industrial-security"} className='dropdown-item ff-baloo nav-link'>INDUSTRIAL SECURITY</Link>
                                            <Link to={"/mobile-patrol"} className='dropdown-item ff-baloo nav-link'>MOBILE PATROL </Link>
                                            <Link to={"/foot-patrol"} className='dropdown-item ff-baloo nav-link'>FOOT PATROL</Link>
                                            <Link to={"/neighborhood-patrol"} className='dropdown-item ff-baloo nav-link'>NEIGHBORHOOD WATCH</Link>
                                            <Link to={"/fire-watch"} className='dropdown-item ff-baloo nav-link'>FIRE WATCH</Link>
                                            <Link to={"/construcion-security"} className='dropdown-item ff-baloo nav-link'>CONSTRUCTION</Link>
                                        </div>
                                    </div>
                                    <Link to={"/about"} className='ff-baloo nav-link nav-title'>About Us</Link>
                                </Nav>
                                <div className='d-flex gap-3'>
                                    <button className='btn bg-red text-uppercase ff-baloo' onClick={() => { setModal(true) }}>Get A Quote</button>
                                    <button className='btn bg-red text-uppercase ff-baloo' onClick={() => {
                                        navigate("/careers")
                                    }}>Join our Team</button>
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                    <Getaquate open={modal} close={() => { setModal(false) }} />
                </Navbar>
            </div>
        </div>
    );
}

export default Header;
