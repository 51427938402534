import React from 'react'
import { Col, Row } from 'react-bootstrap'
import im2 from "../Assests/Image/2-min.jpg"
import im3 from "../Assests/Image/3-min.jpg"
import im4 from "../Assests/Image/10-min.jpg"
function Section() {
    return (
        <div>
            <Row>
                <Col className='m-0 p-0' sm={12} lg={6}><img src={im2} className='w-100' /></Col>
                <Col className='m-0 p-0' sm={12} lg={6}>
                    <div className='bg-blue h-100 p-4 d-flex flex-column justify-content-center text-white'>
                        <h4 className='text-white fw-bold ff-lobster  text-uppercase'>
                            Maximize Your Return Of Investment with In Action Instant Reporting System
                        </h4>
                        <div className='mt-3'>
                            <p>Get instant updates on your phone from our patrols for fast decision-making.</p>
                            <p>Prevent theft and damage with GForce Security, increasing profits.</p>
                            <p>Improve efficiency with streamlined communication and processes.</p>
                            <p>Simplify compliance with digital, paperless reports.</p>
                            <p>Stay proactive and prevent costly issues.</p>
                            <p>Build trust with transparent, instant reporting.</p>
                            <p>Cut costs with our eco-friendly, paperless system.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-none d-lg-block'>
                <Row>
                    <Col className='m-0 p-0' sm={12} lg={6}>
                        <div className='bg-danger h-100 p-4 d-flex flex-column justify-content-center text-white'>
                            <h4 className='text-white fw-bold ff-lobster  text-uppercase'>
                                Affordable Residential and Professional Commercial Security Services You Can Trust
                            </h4>
                            <div className='mt-3'>
                                <p>Do you feel anxious about not finding a security company that can provide security services based on the structure and needs of your home or business? Are you in search of reliable security for your home or business?</p>
                                <p>At In Action Security, we are aware of these concerns and are ready to assist you. With our residential and commercial security services, you are assured of protecting your property. Our highly trained personnel offer professional and expert commercial security services that meet the business’s needs.</p>
                                <p>To homeowners, we provide affordable and the best residential security services to protect your home and your family. With us, you enjoy the best security services at an affordable price in Owen Sound, Richmond Hill, Sudbury, London, Toronto, Mississauga, Hamilton, Brampton and Vaughan. </p>
                                <p>Choose In Action Security as your local security company of choice. Contact us today to learn how we can help safeguard the things and people dear to you.</p>
                            </div>
                        </div>
                    </Col>
                    <Col className='m-0 p-0' sm={12} lg={6}><img src={im3} className='w-100' /></Col>
                </Row>
            </div>
            <div className='d-block d-lg-none'>
                <Row>
                    <Col className='m-0 p-0' sm={12} lg={6}><img src={im3} className='w-100' /></Col>
                    <Col className='m-0 p-0' sm={12} lg={6}>
                        <div className='bg-danger h-100 p-4 d-flex flex-column justify-content-center text-white'>
                            <h4 className='text-white fw-bold ff-lobster  text-uppercase'>
                                Affordable Residential and Professional Commercial Security Services You Can Trust
                            </h4>
                            <div className='mt-3'>
                                <p>Do you feel anxious about not finding a security company that can provide security services based on the structure and needs of your home or business? Are you in search of reliable security for your home or business?</p>
                                <p>At In Action Security, we are aware of these concerns and are ready to assist you. With our residential and commercial security services, you are assured of protecting your property. Our highly trained personnel offer professional and expert commercial security services that meet the business’s needs.</p>
                                <p>To homeowners, we provide affordable and the best residential security services to protect your home and your family. With us, you enjoy the best security services at an affordable price in Owen Sound, Richmond Hill, Sudbury, London, Toronto, Mississauga, Hamilton, Brampton and Vaughan. </p>
                                <p>Choose In Action Security as your local security company of choice. Contact us today to learn how we can help safeguard the things and people dear to you.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col className='m-0 p-0' sm={12} lg={6}><img src={im4} className='w-100' /></Col>
                <Col className='m-0 p-0' sm={12} lg={6}>
                    <div className='bg-white h-100 p-4 d-flex flex-column justify-content-center text-black'>
                        <h4 className='fw-bold ff-lobster  text-uppercase'>
                            Investing in In Action Security Team
                        </h4>
                        <div className='mt-3'>
                            <p>In Action Security recognizes the vital role our security guards play. They are the foundation of our service, safeguarding people and property across Ontario and BC. Our guards are rigorously trained, meeting all physical, educational, and medical standards set by the Ministry of Ontario.</p>
                            <p className='my-3'>We understand the importance of protecting those who protect you. That's why In Action Security invests heavily in our security guards through</p>
                            <p>Competitive salary packages</p>
                            <p>Public Recognition & Employee Recognition Awards</p>
                            <p>Advanced training programs and Career Advancement Opportunities.</p>
                            <p>Personal & Family Fitness memberships & wellness programs.</p>
                            <p>Flexible scheduling for work-life balance.</p>
                            <p>Cut costs with our eco-friendly, paperless system.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Section
