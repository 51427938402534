import React from 'react'
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'

function Getaquate({ open, close }) {
    return (
        <div>
            <Modal size='lg' show={open} onHide={close} centered>
                <Modal.Header className='' closeButton>
                </Modal.Header>
                <Modal.Body className='bg-blue text-white'>
                    <div className='text-center'>
                        <h1 className='fw-bold ff-lobster'>
                            Get Your Security Needs Assessed in Under 30 MINUTES
                        </h1>
                    </div>
                    <div className='text-black mx-5 my-5'>
                        <Row>
                            <Col sm={12}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Full Name"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Enter your Fullname" />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control type="email" placeholder="Enter your email" />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Contact Number"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Enter your Phone Number" />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12}>
                                <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                                    <Form.Select aria-label="Floating label select example">
                                        <option>Select type of Secuirty</option>
                                        <option> EVENT SECURITY</option>

                                        <option> RESIDENTIAL SECURITY</option>
                                        <option> INDUSTRIAL SECURITY</option>

                                        <option> MOBILE PATROL</option>

                                        <option> FOOT PATROL</option>

                                        <option> NEIGHBORHOOD WATCH</option>

                                        <option> FIRE WATCH</option>

                                        <option> CONSTRUCTION</option>

                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <div className='mt-3'>
                                <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                            </div>
                        </Row>
                        <div className='text-white text-center mt-4'>
                            <p>
                                Thank You For Contacting In Action Security
                                <br />
                                We Appreciate Your Inquiry And Will Be In Touch Within 24/7 Hours

                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Getaquate
