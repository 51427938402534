import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import im1 from "../Assests/Image/1-min.jpg"
import im2 from "../Assests/Image/10-min.jpg"
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
function Regdential() {
  return (
    <div>
      <div>
        <Header />
        <div className=''>
          <div className='position-relative'>
            <img src='https://www.magnasecurity.ca/wp-content/uploads/2023/10/INSIDE-BANNER-6.jpg' className='w-100 object-fit-cover' height={400} />
            <div className='position-absolute top-0 header-overlay w-100'>
            </div>
            <div className='position-absolute top-0 h-400 w-100 d-flex justify-content-center align-items-center'>
              <h1 className='text-white fw-bolder ff-lobster'>Residential Security</h1>
            </div>
          </div>
        </div>

        <div>
          <Row >
            <Col>
              <div className='h-100 d-flex flex-column justify-content-center px-4'>
                <h1 className='fw-bolder ff-lobster'>Professional Event Security Services Trusted Across Ontario And Beyond</h1>
                <p className='mt-3'>In Action Security is an expert provider of the best and most affordable event security services across Ontario like Toronto, Mississauga, Brampton, Hamilton, and Vaughan. With over a decade of being a professional and expert event security services provider, In Action Security continuously secures events of all types and sizes by having a team of experts always ready to safeguard and protect your next occasion.</p>
              </div>
            </Col>
            <Col>
              <img src={im1} className='w-100' />
            </Col>
          </Row>
        </div>

        <div>
          <div className='bg-blue text-white py-5'>
            <div className='d-flex justify-content-center'>
              <div className='d-none d-lg-block w-50'>
                <div className='text-center'>
                  <h1 className='fw-bold ff-lobster'>
                    Get Your Security Needs Assessed Under 30 MINUTES
                  </h1>
                </div>
                <div className='text-black mx-5 my-5'>
                  <Row>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Full Name"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Fullname" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control type="email" placeholder="Enter your email" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Contact Number"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Phone Number" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                        <Form.Select aria-label="Floating label select example">
                          <option>Select type of Secuirty</option>
                          <option> EVENT SECURITY</option>

                          <option> RESIDENTIAL SECURITY</option>
                          <option> INDUSTRIAL SECURITY</option>

                          <option> MOBILE PATROL</option>

                          <option> FOOT PATROL</option>

                          <option> NEIGHBORHOOD WATCH</option>

                          <option> FIRE WATCH</option>

                          <option> CONSTRUCTION</option>

                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <div className='mt-3'>
                      <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                    </div>
                  </Row>
                  <div className='text-white text-center mt-4'>
                    <p>
                      Thank You For Contacting Inaction Security
                      <br />
                      We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                    </p>
                  </div>
                </div>
              </div>
              <div className='d-block d-lg-none'>
                <div className='text-center'>
                  <h1 className='fw-bold ff-lobster'>
                    Get Your Security Needs Assessed Under 30 MINUTES
                  </h1>
                </div>
                <div className='text-black mx-5 my-5'>
                  <Row>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Full Name"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Fullname" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control type="email" placeholder="Enter your email" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Contact Number"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Enter your Phone Number" />
                      </FloatingLabel>
                    </Col>
                    <Col sm={12}>
                      <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                        <Form.Select aria-label="Floating label select example">
                          <option>Select type of Secuirty</option>
                          <option> EVENT SECURITY</option>

                          <option> RESIDENTIAL SECURITY</option>
                          <option> INDUSTRIAL SECURITY</option>

                          <option> MOBILE PATROL</option>

                          <option> FOOT PATROL</option>

                          <option> NEIGHBORHOOD WATCH</option>

                          <option> FIRE WATCH</option>

                          <option> CONSTRUCTION</option>

                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <div className='mt-3'>
                      <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                    </div>
                  </Row>
                  <div className='text-white text-center mt-4'>
                    <p>
                      Thank You For Contacting Inaction Security
                      <br />
                      We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Row >
            <Col>
              <img src={im2} className='w-100' />
            </Col>
            <Col>
              <div className='h-100 d-flex flex-column justify-content-center px-4'>
                <h1 className='fw-bolder ff-lobster'>Professional Event Security Services in Toronto, Ontario</h1>
                <p className='mt-3'>As a professional security company, In Action Security provides expert security services by recognizing the distinct security issues connected to various gatherings, ranging from corporate events, music festivals, and conferences to celebrity weddings and local festivities. In order to let you relax and enjoy your event, our skilled security officers collaborate alongside you to evaluate hazards, control crowds, and handle emergencies.</p>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Regdential
