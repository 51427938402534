import React from 'react'
import logo from "../Assests/Image/logo.jpg"
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosTime } from "react-icons/io";
function Footer() {
    return (
        <footer className='bg-blue'>
            <div className='container py-4'>
                <div className='row w-100'>
                    <div className='col col-lg-3 d-flex justify-content-lg-center flex-column align-items-lg-center gap-3'>
                        <img src={logo} width={100} height={100} className='rounded' />
                        <p className='text-white'>
                            Proudly protecting people and properties through our armed and unarmed security services.
                        </p>
                    </div>
                    <div className='col text-white ms-lg-4 d-flex flex-column gap-3'>
                        <h1 className='ff-lobster text-white'>
                            Contact Info
                        </h1>
                        <div>
                            <FaPhoneAlt  className='me-2'/>
                            +1 437 438 2323
                        </div>
                        <div>
                            <MdEmail className='me-2'/> operations@inaction247.com
                        </div>
                        <div><FaLocationDot className='me-2'/> 7747 Wildfern Drive, Mississauga,ON, Canada,L4T 3Ps</div>
                    </div>
                    <div className='col text-white d-flex flex-column gap-lg-3 mt-4 mt-lg-0'>
                        <h1>Business Hours</h1>
                        <p><IoIosTime className='me-2'/> 09:00 am to 05:00 pm</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
