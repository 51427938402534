import React from 'react'
import { Col, Row } from 'react-bootstrap'
import im1 from "../Assests/Image/gaurd.jpg"
function Information() {
    return (
        <div className='container py-4 '>
            <Row>
                <Col className='h-100' xs={12} md={6}>
                    <div className='mt-5' data-aos="fade-right">
                        <h1 className='fw-bold ff-lobster'>INACTION -WHERE SECURITY MEETS PROTECTION</h1>
                        <p className='mt-3'>Experience top-tier protection with IN ACTION 24/7 SECURITY: bespoke services, expert guards, instant reporting, and 24/7 support</p>
                        <p>IN ACTION 24/7 SECURITY is a leading provider in Ontario, specializing in GTA areas. We offer physical and procedural security services with highly trained static and mobile guards for residential, commercial, and industrial needs. Our commitment includes real-time monitoring and ongoing training to stay ahead of threats.</p>
                        <p>With over 6 years of experience protecting more than 150 locations, we've secured families, communities, businesses, and major events. Choose us for a trusted partner dedicated to your safety and security.</p>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div data-aos="fade-left">
                        <img src={im1} className='w-100 object-fit-cover rounded' height={550} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Information
