import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'

function Careers() {
    return (
        <div>
            <Header />
            <div className='container '>
                <h1 className='fw-bold ff-lobster text-center clr-blue'>
                    Join Our Team
                </h1>
                <p className='text-center'>At In Action Security, our employees are our greatest asset. We seek dedicated professionals who share our commitment to integrity, vigilance, and discretion. With competitive wages, excellent benefits, and abundant opportunities for growth, we aim to be an employer of choice in the security industry.</p>
                <p className='text-center'>As an equal opportunity employer, we value diversity in all its forms. Integrity, vigilance and discretion define our work and we collaborate closely with our clients, to design comprehensive security programs tailored to their unique needs.</p>

                <h3 className='fw-bold ff-lobster text-center text-danger'>To join our growing team, please submit the form below. Positions fill quickly, so apply today!</h3>

                <div className='card p-4 mx-lg-5 mx-3 mb-4 mt-4'>
                    <Row>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Full Name"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter your Fullname" />
                            </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                className="mb-3"
                            >
                                <Form.Control type="email" placeholder="Enter your email" />
                            </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Contact Number"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter your Phone Number" />
                            </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Work Experience (in years)"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Enter your Work experience (in years)" />
                            </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Date of Birth"
                                className="mb-3"
                            >
                                <Form.Control type="date" placeholder="Enter your Phone Number" />
                            </FloatingLabel>
                        </Col>
                        <Col sm={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Resume"
                                className="mb-3"
                            >
                                <Form.Control type="file" placeholder="Upload your Resume" accept=".pdf" />
                            </FloatingLabel>
                        </Col>
                        <div className='mt-3'>
                            <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Submit</button>
                        </div>
                    </Row>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Careers
