import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import img1 from '../Assests/Image/1-min.jpg';
import img2 from '../Assests/Image/2-min.jpg';
import img3 from '../Assests/Image/3-min.jpg';
import img4 from '../Assests/Image/4-min.jpg';
import img5 from '../Assests/Image/5-min.jpg';
import img6 from '../Assests/Image/6-min.jpg';
import img7 from '../Assests/Image/7-min.jpg';
import img8 from '../Assests/Image/8-min.jpg';
import img9 from '../Assests/Image/9-min.jpg';
import img10 from '../Assests/Image/10-min.jpg';
import img11 from '../Assests/Image/11-min.jpg';
import img12 from '../Assests/Image/12-min.jpg';

function Gallery() {
    const images = [
        img1, img2, img3, img4, img5, img6,
        img7, img8, img9, img10, img11, img12
    ];

    return (
        <div>
            <Header />
            <div className="container">
                <div className='my-5 text-center'>
                    <h1 className='fw-bold ff-lobster'>Gallery</h1>
                </div>
                <Row>
                    {images.map((image, index) => (
                        <Col key={index} sm={12} md={4} className="mb-4">
                            <div className="gallery-item ">
                                <img src={image} alt={`Gallery ${index + 1}`} className="img-fluid rounded" />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <Footer />
        </div>
    );
}

export default Gallery;
