import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import imge from "../Assests/Image/8.jpg"
function AboutUs() {
    return (
        <div>
            <Header />
            <div>
                <div className='container my-5'>
                    <div className='d-flex flex-wrap align-items-center gap-5'>
                        <Row>
                            <Col sm={12} lg={5}>
                                <img src={imge} className='rounded w-100' />
                            </Col>
                            <Col sm={12} lg={7}>
                                <div className='h-100 d-flex justify-content-center flex-column mt-3 mt-lg-0'>
                                    <h3 className='fw-bold ff-lobster'>Our Mission</h3>
                                    <p className='fs-5'>Our mission at InAction Security is to provide the highest quality security solutions to our clients. We are committed to delivering reliable and effective security services that protect people and property while maintaining a safe and secure environment. We aim to establish long-lasting relationships with our clients by delivering exceptional customer service and innovative security solutions that meet their unique needs.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='bg-blue text-white py-5'>
                    <div className='d-flex justify-content-center'>
                        <div className='d-none d-lg-block w-50'>
                            <div className='text-center'>
                                <h1 className='fw-bold ff-lobster'>
                                    Get Your Security Needs Assessed Under 30 MINUTES
                                </h1>
                            </div>
                            <div className='text-black mx-5 my-5'>
                                <Row>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Full Name"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="Enter your Fullname" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email" placeholder="Enter your email" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contact Number"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="Enter your Phone Number" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                                            <Form.Select aria-label="Floating label select example">
                                                <option>Select type of Secuirty</option>
                                                <option> EVENT SECURITY</option>

                                                <option> RESIDENTIAL SECURITY</option>
                                                <option> INDUSTRIAL SECURITY</option>

                                                <option> MOBILE PATROL</option>

                                                <option> FOOT PATROL</option>

                                                <option> NEIGHBORHOOD WATCH</option>

                                                <option> FIRE WATCH</option>

                                                <option> CONSTRUCTION</option>

                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <div className='mt-3'>
                                        <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                                    </div>
                                </Row>
                                <div className='text-white text-center mt-4'>
                                    <p>
                                        Thank You For Contacting Inaction Security
                                        <br />
                                        We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                            <div className='text-center'>
                                <h1 className='fw-bold ff-lobster'>
                                    Get Your Security Needs Assessed Under 30 MINUTES
                                </h1>
                            </div>
                            <div className='text-black mx-5 my-5'>
                                <Row>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Full Name"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="Enter your Fullname" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email" placeholder="Enter your email" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contact Number"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="Enter your Phone Number" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FloatingLabel controlId="floatingSelect" label="Type Of Secuirty">
                                            <Form.Select aria-label="Floating label select example">
                                                <option>Select type of Secuirty</option>
                                                <option> EVENT SECURITY</option>

                                                <option> RESIDENTIAL SECURITY</option>
                                                <option> INDUSTRIAL SECURITY</option>

                                                <option> MOBILE PATROL</option>

                                                <option> FOOT PATROL</option>

                                                <option> NEIGHBORHOOD WATCH</option>

                                                <option> FIRE WATCH</option>

                                                <option> CONSTRUCTION</option>

                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <div className='mt-3'>
                                        <button className='btn-danger btn w-100 fs-5 py-2 rounded-pill'>Book A Free Consultation</button>
                                    </div>
                                </Row>
                                <div className='text-white text-center mt-4'>
                                    <p>
                                        Thank You For Contacting Inaction Security
                                        <br />
                                        We Appreciate Your Inquiry And Will Be In Touch Within 24 Hours

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container my-5'>

                    <div className='d-flex flex-wrap align-items-center gap-5'>
                        <Row>
                            <Col sm={12} lg={7}>
                                <div className='h-100 d-flex justify-content-center flex-column'>
                                    <h3 className='fw-bold ff-lobster'>Our Vision</h3>
                                    <p className='fs-5'>Our vision at InAction Security is to be the leading security company in Canada, recognized for our professionalism, reliability, and exceptional customer service. We strive to continuously improve our security solutions by utilizing the latest technology and best practices in the industry. We aim to create a culture of safety and security in Canada by partnering with our clients and local communities to provide proactive and responsive security services that enhance public safety and prevent crime.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} lg={5}>
                                <img src={"https://images.squarespace-cdn.com/content/v1/5f57607a3e5b891978a992b9/1663915253943-EXO2FFWC3ORPOMTTNTJV/cover-security-guard.jpg"} className='rounded w-100' />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs
