import React from 'react'
import Header from '../Component/Header'
import Hero from '../Component/Hero'
import Information from '../Component/Information'
import Serivces from '../Component/Serivces'
import Inovence from '../Component/Inovence'
import Footer from '../Component/Footer'
import Why from '../Component/Why'
import Section from '../Component/Section'

function Home() {
  return (
    <div>
        <Header/>
        <Hero/>
        <Information/>
        <Serivces/>
        {/* <Inovence/>  */}
        <Section/>
        <Why/>
        <Footer/>
    </div>
  )
}

export default Home
